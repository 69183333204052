.dropdown {
    display: inline-block;
}
.dropdown__list {
    line-height: 40px;
    width: 100%;
    max-width: 300px;
    margin: 12px;
    font-size: 16px;
    background-color: transparent;
    color: $text_default;
    border: 0;
    border-bottom: 1px solid $border-grey;
}
.dropdown__list-with-legend {
    width: 80%;
    float: right;
}
.dropdown__list-item {
    background-color: white;
}
.dropdown__legend {
    display: inline-block;
    width: 10%;
    padding-top: 15px;
}
.dropdown__legend-solid {
    border-top: 2px solid;
}
.dropdown__legend-dashed {
    border-top: 2px dashed;
    border-bottom: 0;
}

.dropdown__title {
    font-weight: 700;
    margin: 15px 0px 0px 12px;
    letter-spacing: 0.04rem;
}
