@import './../bmixins'; //Import all sass snippets
@import './../global'; // Import Theme Globals

.search__holder {
    display: inline-flex;
    width: 100%;
}

.search-options {
    align-items: baseline;

    &.date-picker,
    &.live {
        margin-right: 10px;
    }

    &.terms {
        flex-grow: 2;
    }

    &.bar {
        flex-grow: 15;
        margin-bottom: 18px;
        margin: 0px 10px;
    }

    &.submit {
        max-width: 85px;
        flex-grow: 1;
        margin-top: 2px;
    }
}
