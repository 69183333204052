.account-configuration {
    display: flex;

    &__side-bar {
        overflow: auto;
        position: sticky;
        top: 100px;
        padding-bottom: 30px;
        max-height: 90vh;

        & .bread-crumbs__search {
            margin-bottom: 30px;
        }

        & .bread-crumb__home {
            background-color: $white;
        }
    }

    &__sidebar-container {
        width: 25%;

        @media (max-width: 1650px) {
            width: 35%;
        }

        @media (max-width: 1200px) {
            width: 50%;
        }
    }

    &__list {
        width: 80%;
    }
}
