.list-row__check-copy {
    display: table;
    border-bottom: solid 2px $light-grey;
    // padding: 5px 0;
    width: 100%;
    cursor: pointer;

    &__check {
        width: 30px;
        display: table-cell;
        padding: 0 5px;
    }
    &__copy {
        width: calc(100% - 32px);
        padding: 0 15px;
        display: table-cell;
        vertical-align: middle;
        border-left: solid 2px $light-grey;
        & p {
            margin: 0;
        }
    }
}
