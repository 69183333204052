.sunburst-chart {
    &__tooltip {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 170px;
        text-align: center;
        & p {
            font-size: 0.9em;
            margin: 0;
        }
        & h1 {
            font-weight: 500;
            color: $green;
            font-size: 2.6em;
            margin: 0 0 10px 0;
        }
    }
}
