.input-field {
    width: 100%;
    height: 40px;
    background-color: $input_colour;
    border: none;
    border-bottom: solid 2px $off-black-lighter;
    padding: 0 5px;
    margin-bottom: 5px;
    border-radius: 2px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        font-weight: 300;
        font-size: 0.9em;
    }
    &__select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: $input_colour;
        background-image: url(/assets/images/icons/sort.png);
        background-size: 0.9em;
        background-repeat: no-repeat;
        background-position: right 5px center;
    }
}

.input-disabled {
    cursor: not-allowed !important;
    background-color: $light-grey;
    border-bottom: solid 2px #8c8c8c;
    color: #8c8c8c;
}
