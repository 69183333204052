.popup {
    z-index: 500001;
    position: fixed;
    background-color: transparentize($dark, 0.3);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    &__dialog {
        cursor: initial;
        background-color: $white;
        max-width: 600px;
        width: 100%;
        padding: 20px;
        border-radius: 2px;
        margin: auto;
        margin-top: 50vh;
        transform: translateY(-50%);
        &__header {
            margin: 0 -20px 15px -20px;
            padding: 0 20px;
            &__icon {
                display: inline-block;
                height: 26px;
                width: 26px;
                background-position: center;
                background-size: contain;
                margin-bottom: -5px;
                &.warning {
                    background-image: url('/assets/images/icons/warning-icon.png');
                }
                &.error {
                    background-image: url('/assets/images/icons/error-icon.png');
                }
                &.informative {
                    background-image: url('/assets/images/icons/informative-icon.png');
                }
            }
            & h3 {
                margin: 0 0 15px 10px;
                display: inline-block;
            }
            border-bottom: solid 2px $light-grey;
        }
        &__actions {
            text-align: right;
            & .input-field__button {
                display: inline-block;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0px;
                }
            }
        }

        &.importIntegrationAccounts {
            max-width: 900px;
        }
        &.createOauthConnection {
            max-width: 650px;
        }
    }
}
