.warning-message {
    &__holder {
        position: relative;
        margin: auto;
        width: 300px;
        height: 100px;
        background-color: #fff;
        border-radius: 3px;
        font-size: 13px;
        color: $primary;
        margin: auto;
    }

    &__copy {
        transform: translateY(50%);
        position: absolute;
        text-align: left;
        width: 200px;
        word-wrap: break-word;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 12px;
    }

    &__icon {
        border-radius: 3px;
        margin-left: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        left: 0;
        background: url('/assets/images/icons/error-icon.png') center center no-repeat;
        background-size: contain;
    }
}
