.tag {
    background-color: $white;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    margin: 5px;
    padding: 0px 10px;
    color: $light;
    position: relative;
    border: solid 0.3px $off-light;
    height: 30px;
    & input {
        border: 0;
    }

    &.error {
        & svg {
            fill: $red;
        }
        color: $red;
        border: solid 0.3px $red;

        & input {
            color: $red;
        }

        & span {
            color: $red;
        }
    }

    &.editing {
        color: $primary;
        border: solid 0.3px;
    }

    & input {
        margin: 0;
        outline: none;
        color: $text-default;
        background-color: $white;
        padding: 0px;
    }

    & svg {
        fill: $text-default;
        position: relative;
        margin-left: 12px;
        top: 1px;
        height: 12px;
        &:hover {
            cursor: pointer;
        }
    }

    & span {
        color: $text_default;
        padding: 0px;

        &.truncate-string {
            max-width: 230px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                max-width: unset;
                cursor: default;
                white-space: unset;
                overflow: unset;
                text-overflow: unset;
            }
        }
    }
}
