.sidebar__menu {
    &__list {
        margin-bottom: 20px;
    }
    & ul {
        padding-left: 25px;
        & li {
            list-style-type: none;
            & h4 {
                margin: 0 0 7px 0;
                cursor: pointer;
                font-size: 1.1em;
            }
            & p {
                margin: 0 0 5px 0;
                font-size: 1em;
                font-weight: 300;
                cursor: pointer;
            }
        }
    }
}
