.modal__side-panel__manage-users {
    &__users {
        & .simple-table__table {
            &__header {
                display: table;
                width: 100%;
                table-layout: fixed;
            }
            &__row {
                display: table;
                width: 100%;
                table-layout: fixed;
            }

            & tbody {
                display: block;
                max-height: 200px;
                height: 100%;
                overflow-y: scroll;
            }
        }
    }
}
