.table-search {
    &__wrapper {
        width: 100%;
        position: relative;
        top: 2px;
        &.offset {
            top: 0;
        }
        bottom: 6px;
        &__search {
            float: right;
            position: relative;
            top: -4px;
            width: 200px;
        }
    }
}
