.two-step-select {
    display: inline;

    &__error {
        color: $red;
    }

    & label {
        display: block;
        margin-left: 6px;
        font-size: 1.1em;
        font-weight: 400;
    }

    &__container {
        display: inline-flex;
        justify-content: space-around;
        &__item {
            padding: 6px;
        }
    }

    & h3 {
        margin-left: 6px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
