.form-field {
    padding-right: 15px;
    position: relative;
    display: block;
    margin-bottom: 15px;

    &__top {
        margin-bottom: 5px;
        & label {
            font-size: 1em;
            font-weight: 400;
        }
        &__required-field {
            color: $red;
        }

        & .tooltip {
            float: right;
        }
    }

    &__text {
        &.error {
            & input {
                border-color: $red;
            }
        }
    }

    &__prefix {
        position: relative;

        & input {
            padding-left: 40px;
        }

        &__text {
            color: #58b5c4;
            position: absolute;
            top: 10px;
            left: 5px;
            pointer-events: none;
        }
    }

    &__select {
        &.error {
            & select {
                border-color: $red;
            }
        }
    }

    &__error-message {
        font-size: 0.9em;
        color: $red;
        margin-bottom: 1em;
    }

    &__checkbox-label {
        display: inline-block;
    }

    &__checkbox {
        cursor: pointer;
        display: inline-block;
        position: relative;
        top: -15px;
        left: 10px;
    }
}

.api-export__header {
    display: flex;
    margin-top: 1.5rem;

    &__label {
        margin-right: 0.7rem;
    }
}
