.list-builder {
    & h3 {
        margin: 0 0 5px 0;
    }
    &__list {
        width: 100%;
        height: 200px;
        background-color: $input_colour;
        border-radius: 2px;
        margin: 0 0 10px 0;
        padding: 0;

        &-left {
            max-width: calc(50% - 25px);
            flex: calc(50% - 30px);
        }
        &-right {
            max-width: calc(50% - 25px);
            flex: calc(50% - 30px);
        }

        &__item {
            list-style: none;
            padding: 10px;
            border-bottom: solid 1px $light;
            font-size: 0.9em;
            font-weight: 400;
            cursor: pointer;
            &.selected {
                background-color: darken($input_colour, 10);
            }
            &.empty {
                padding-top: 40px;
                text-align: center;
                border: none;
                cursor: initial;
            }
        }
    }

    &__central-buttons {
        max-width: 60px;
        flex: 50px;
        text-align: center;
        & .input-field__button {
            margin-bottom: 10px;
        }
    }
}
