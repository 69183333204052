.accordion {
    &__item {
        background-color: $white;
        border-radius: 2px;
        margin-bottom: 5px;
        &-header {
            padding: 10px 15px;
            border-bottom: solid 2px $light-grey;
            cursor: pointer;
            &__icon {
                display: inline-block;
                margin-right: 15px;
                & .icon {
                    & svg {
                        fill: $light-grey;
                    }
                }
            }
            & h3 {
                display: inline-block;
                margin: 0;
                & .required {
                    color: $red;
                }
            }
        }

        &-content {
            display: none;
            padding: 15px;
            &__intro {
                margin-bottom: 20px;
                & a {
                    color: $primary;
                }
            }
        }

        &.active {
            & .accordion__item-content {
                display: block;
            }
            & .accordion__item-header__icon {
                & .icon {
                    & svg {
                        transform: rotate(180deg) translateY(-5px);
                    }
                }
            }
        }
    }
}
