@import './../bmixins'; //Import all sass snippets
@import './../global'; // Import Theme Globals

.event-sequencer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    &__action {
        display: flex;
        justify-content: flex-start;
        align-items: self-end;
    }

    &__loading {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        text-align: center;
        vertical-align: middle;
        &__cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(4px);
        }
        &__message {
            padding-top: 24px;
            position: absolute;
            left: 25%;
            top: 40%;
            width: 50%;
            height: 20%;
            @include box_shadow(1);
            background-color: $light;
            z-index: 12;
        }
        &__bar {
            position: absolute;
            top: 60%;
            left: 15%;
            width: 70%;
            height: 12px;
            background-color: $light-grey;
            overflow: hidden;
            &:before {
                display: block;
                position: absolute;
                content: '';
                left: -200px;
                width: 70%;
                height: 12px;
                background-color: #2980b9;
                animation: loading 2s linear infinite;
            }
        }
        &.show {
            position: absolute;
            visibility: visible;
            opacity: 1;
            & .loading-spinner {
                position: absolute;
                top: 80px;
                width: 100%;
                text-align: center;
            }
        }
    }
    &__list {
        height: 100%;
        background-color: $light;
        padding: 6px;
        width: 100%;
        border: 1px solid $hover-grey;
        &.dragged-over {
            border: 1px solid $primary;
            background-color: $hover-grey;
        }
        &__holder {
            flex: 1 1 auto;
        }
    }
    &__buttons {
        width: 100%;
        flex: 0 1 40px;
        text-align: right;
    }
    &__item {
        position: relative;
        @include box-shadow(1);
        @include no_select();
        background-color: $light;
        border: 1px solid $light;
        padding: 4px 12px;
        transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
        margin: 6px;
        &.dragging {
            @include box-shadow(3);
            border: 1px solid $primary;
            background-color: $light;
        }
    }
}
@keyframes loading {
    from {
        left: -200px;
        width: 30%;
    }
    50% {
        width: 30%;
    }
    70% {
        width: 70%;
    }
    80% {
        left: 50%;
    }
    95% {
        left: 120%;
    }
    to {
        left: 100%;
    }
}
