@import './../bmixins'; //Import all sass snippets
@import './../global'; // Import Theme Globals

.contribution-analysis {
    &__chart {
        position: relative;
        width: 45%;
        margin: 1.25%;
        float: left;
        min-height: 560px;
        flex-grow: 1;
        &__skeleton {
            overflow: hidden;
            width: 100%;
            height: 100%;
            position: absolute;
            &.circle {
                position: absolute;
                border-radius: 60%;
                width: 60%;
                height: auto;
                padding-top: 60%;
                left: 25%;
                top: 25%;
                background: pink;
                @include skeletonLoader;
                &:nth-child(1) {
                    left: 10%;
                }
                &:nth-child(2) {
                    left: 40%;
                }
                &:nth-child(3) {
                    top: -20%;
                    background: $off-light;
                }
                &:nth-child(4) {
                    top: 70%;
                    background: $off-light;
                }
            }
        }
    }
    &__table {
        position: relative;
        width: 100%;
        opacity: 1;
        transition: $transition;
        &.loading {
            opacity: 0.3;
        }
        &__skeleton {
            & table {
                width: 100%;
                margin: 40px 0;
                position: relative;
                & tr {
                    position: relative;
                    @include skeletonLoader;
                    width: 40%;
                    border-radius: 2px;
                    display: block;
                    height: 12px;
                    margin: 24px;
                    &:nth-child(2n) {
                        width: 60%;
                    }
                    &:nth-child(3n) {
                        width: 80%;
                    }
                }
            }
        }
        &__wrapper {
            background-color: $white;
            @include box_shadow(1);
            position: relative;
            width: 100%;
            margin: 1.25%;
            padding: 7px;
            float: right;
            min-height: 78vh;
            max-height: 78vh;
            overflow-y: auto;
        }

        & td,
        th {
            position: relative;
            border: 1px solid $border-grey;
            & div {
                display: inline-block;
                position: relative;
                padding: 12px;
                margin-left: 22px;
            }
            & .channel-colour {
                margin: 0;
                position: absolute;
                width: 22px;
                height: 100%;
            }
        }
    }
}

.fa-arrow-up {
    transition: 0.2s ease-in-out;
    position: absolute;
    left: 10px;
    top: calc(50% - 6px);
    transform: rotate(0deg);
    display: inline;
    vertical-align: middle;
    opacity: 0;
    height: 12px;
    width: 12px;
    background-image: url('/assets/images/icons/icon-arrowup-black.svg');
    background-size: contain;
    background-repeat: no-repeat;
}
.table__head__cell .fa-arrow-up.desc {
    transform: rotate(180deg);
}
.table__head__cell .fa-arrow-up.visible {
    opacity: 0.5;
}
