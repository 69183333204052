.page-icon {
    &__lc-vs-fm {
        background: url('/assets/images/icons/lc-vs-fm.png') no-repeat center center;
    }
    &__landing {
        background: url('/assets/images/icons/landing-page-icon.png') no-repeat center center;
        background-size: contain;
    }
    &__adwords {
        background: url('/assets/images/icons/bidadjust-img.png') no-repeat center center;
    }
    &__ppc {
        background: url('/assets/images/icons/ppclcfm.png') no-repeat center center;
    }
    &__ppc-impression-share {
        background: url('/assets/images/icons/ppc-impression-share.png') no-repeat center center;
        background-size: contain;
    }
    &__keywords {
        background: url('/assets/images/icons/keyword-icon.png') no-repeat center center;
        background-size: contain;
    }
    &__seo {
        background: url('/assets/images/icons/seolcfm.png') no-repeat center center;
    }
    &__seo-keyword {
        background: url('/assets/images/icons/seo-keyword.png') no-repeat center center;
        background-size: contain;
    }
    &__seo-google {
        background: url('/assets/images/icons/seo-google.png') no-repeat center center;
        background-size: contain;
    }
    &__seo-traffic {
        background: url('/assets/images/icons/seo-traffic.png') no-repeat center center;
        background-size: contain;
    }
    &__seo-forecast {
        background: url('/assets/images/icons/seo-forecast.png') no-repeat center center;
        background-size: contain;
    }
    &__sku {
        background: url('/assets/images/icons/ecomm-sku-icon.png') no-repeat center center;
        background-size: contain;
    }
    &__event-funnel {
        background: url('/assets/images/icons/funnels-icon.png') no-repeat center center;
        background-size: contain;
    }
    &__scv {
        background: url('/assets/images/icons/scv-icon.png') no-repeat center center;
        background-size: contain;
    }
    &__account-manage {
        background: url('/assets/images/icons/account-management.png') no-repeat center center;
        background-size: contain;
    }
    &__account-configuration {
        background: url('/assets/images/icons/account-configuration.png') no-repeat center center;
        background-size: contain;
    }
    &__account-health {
        background: url('/assets/images/icons/account-health.png') no-repeat center center;
        background-size: contain;
    }
    &__contribution {
        background: url('/assets/images/icons/contrib-img.png') no-repeat center center;
        background-size: contain;
    }
    &__journey-analysis {
        background: url('/assets/images/icons/journey-analysis.png') no-repeat center center;
        background-size: contain;
    }
    &__seo-marketshare {
        background: url('/assets/images/icons/seo-marketshare-icon.png') no-repeat center center;
        background-size: contain;
    }
    &__atl {
        background: url('/assets/images/icons/atl.png') no-repeat center center;
        background-size: contain;
    }
    &__monthly-performance {
        background: url('/assets/images/icons/monthly-performance.png') no-repeat center center;
        background-size: contain;
    }
    &__page-performance {
        background: url('/assets/images/icons/page-performance.png') no-repeat center center;
        background-size: contain;
    }
    &__market-basket {
        background: url('/assets/images/icons/market-basket.png') no-repeat center center;
        background-size: contain;
    }
    &__visit-in-journey {
        background: url('/assets/images/icons/visit-in-journey.png') no-repeat center center;
        background-size: contain;
    }
    &__impression-frequency-capping {
        background: url('/assets/images/icons/impression-frequency-capping.png') no-repeat center center;
        background-size: contain;
    }
    &__sale-number {
        background: url('/assets/images/icons/sale-number.png') no-repeat center center;
        background-size: contain;
    }
}
