.button__list {
    display: flex;
}
.button {
    @include edge($primary);
    @include no_select();
    background-color: $off-black;
    box-sizing: border-box;
    font-size: 13px;
    cursor: pointer;
    border-radius: 3px;
    color: $white;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    line-height: 32px;
    padding: 0 12px;
    transition: 0.2s ease-in;
    flex-shrink: 0;
    width: 100%;
    &.disabled {
        cursor: not-allowed;
        $colour: $secondary;
        border-bottom: 1px solid $colour !important;
        border-top: 1px solid $colour !important;
        color: $white !important;
        @include edge($colour);
        @include hover($colour);
        background-color: $colour !important;
    }
    &:hover {
        @include box_shadow(0.5);
    }
    &:active {
        @include box_shadow(0);
    }
    &.small {
        line-height: 18px;
        padding: 0 6px;
    }
    &.large {
        line-height: 48px;
        padding: 0 12px;
    }
    &.primary {
        $colour: $off-black;
        @include edge($colour);
        @include hover($colour);
        background-color: $colour;
    }
    &.secondary {
        $colour: $secondary;
        color: $text-default;
        @include edge($colour);
        @include hover($colour);
        background-color: $colour;
    }
    &.warning {
        $colour: $warning;
        @include edge($colour);
        @include hover($colour);
        background-color: $colour;
    }
    &.danger {
        $colour: $danger;
        @include edge($colour);
        @include hover($colour);
        background-color: $colour;
    }
    &.success {
        $colour: $success;
        @include edge($colour);
        @include hover($colour);
        background-color: $colour;
    }
    &.info {
        $colour: $info;
        @include edge($colour);
        @include hover($colour);
        background-color: $colour;
    }
    &.link {
        $colour: $link;
        @include hover($colour);
        background-color: transparent;
        border: 1px solid $link;
        color: $link;
    }

    &__wrapper {
        flex-grow: 1;
        position: relative;
        display: inline-block;
        padding: 6px;
        &.full-width {
            width: 100%;
        }
    }
    &__skeleton {
        width: 100px;
        height: 30px;
        padding: 6px;
        margin: 6px;
        @include skeletonLoader;
    }
}

.react-ripples {
    width: 100%;
}
