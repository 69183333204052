@import './../bmixins'; //Import all sass snippets
@import './../global'; // Import Theme Globals

.funnel {
    position: relative;
    width: 100%;
    flex: 1 1 auto;
    max-height: calc(100% - 66px);
    &__title {
        text-align: center;
        width: 100%;
        font-size: 18px;
        flex: 0 1 30px;
    }
    &__event-selection {
        @include box_shadow(1);
        float: left;
        padding: 6px;
        margin: 3px;
        height: 480px;
        width: calc(25% - 6px);
        display: inline-block;
        background-color: $white;
    }
    &__wrapper {
        position: relative;

        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &__names__holder {
        position: relative;
        width: 100%;
        flex: 0 1 auto;
        min-height: 66px;
    }
    &__name {
        position: relative;
        display: inline-block;
        text-align: center;
        padding: 6px 0;
        vertical-align: middle;
        & div {
            text-transform: uppercase;
            padding-right: 50%;
            font-size: 12px;
        }
    }
    &__section {
        position: relative;
        height: 100%;
        max-height: 100%;
        display: inline-block;
        align-items: center;
        justify-content: center;
    }
}

.funnel__widget {
    @include box_shadow(1);
    display: inline-block;
    position: relative;
    width: calc(75% - 6px);
    height: 480px;
    max-height: 480px;
    padding: 6px;
    margin: 3px;
    &.channel {
        width: calc(25% - 6px);
        height: 240px;
        padding-bottom: 6px;
    }
    &__loader {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(4px);
        &__message {
            position: absolute;
            left: 25%;
            top: 25%;
            width: 50%;
            height: 50%;
            @include box_shadow(1);
            background-color: $white;
        }
    }
}
