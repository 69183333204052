.list-widget__header {
    &:not(:empty) {
        background-color: $white;
        border-bottom: 2px solid $light-grey;
    }
    &-text {
        padding: 15px 20px;
        & h2 {
            color: $off-black-lighter;
            margin: 0;
        }
    }
    &-check-title {
        & p {
            border-left: solid 2px $light-grey;
            font-weight: 500;
            padding: 10px 15px;
            margin: 0 30px;
        }
    }
}
