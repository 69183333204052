@import '../_global.scss';
.table-graph {
    min-height: 420px;
    position: relative;
    width: 100%;

    &__no-data {
        width: 100%;
        position: relative;
        & .message {
            &__text {
                transform: translateY(-10px);
                line-height: 18px;
                margin-left: 14px;
                width: auto;
            }
            & svg {
                height: 55px;
            }
        }
    }

    &__options {
        position: relative;
        display: block;
        width: 100%;
    }
}
