.multi-select-dropdown {
    &:hover .level-one {
        display: block;
    }

    & .add-icon {
        background-color: $input_colour;
        color: #000000;
        font-size: 0.9rem;
        width: 30px;
        height: 30px;
        margin: 5px 0 0 10px;
        border-radius: 50%;
        padding: 8px;

        & .icon {
            & svg {
                height: 12px;
                width: 12px;
                fill: $text_default;
            }
        }
    }

    & .chevron-container {
        margin-left: auto;
    }

    & .icon-chevron-right {
        & svg {
            width: 10px;
            fill: $text_default;
            margin: 2px 4px 0 0;
        }
    }

    & li {
        background-color: #ffffff;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        z-index: 50;
        height: 30px;

        &:hover {
            background-color: $input_colour;
        }
    }

    & .level-one {
        @include dropShadow(5px, 5px, 3px, 0px, rgba(0, 0, 0, 0.1));
        display: none;
        position: absolute;

        & li {
            position: relative;
            width: 240px;

            &:hover .level-two {
                display: block;
            }
        }
    }

    & .level-two {
        display: none;
        position: absolute;
        top: 0px;

        & li {
            @include dropShadow(5px, 5px, 3px, 0px, rgba(0, 0, 0, 0.1));
            position: relative;
            left: 240px;

            &:hover .level-three {
                display: block;
            }
        }
    }

    & .level-three {
        display: none;
        position: absolute;
        top: 0px;

        & li {
            @include dropShadow(5px, 5px, 3px, 0px, rgba(0, 0, 0, 0.1));
            position: relative;
            left: 240px;
        }
    }

    & .colour-tile {
        height: 30px;
        width: 12px;
    }

    & .colour-tile-level-one {
        height: 30px;
        width: 0px;
    }
}
