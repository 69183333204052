@import './_global';

.table__holder {
    max-width: 100%;
    background-color: #fff;
    overflow: visible;
    overflow-x: auto;
    border: 1px solid $border-grey;
}

.table {
    width: 100%;
    border-collapse: separate;
    & tr {
        position: relative;
    }

    & tbody {
        background-color: transparent;
        opacity: 1;
        transition: $transition;
        &.loading {
            background-color: $selection-grey;
            opacity: 0.5;
        }
    }

    .no-data {
        text-align: center;
        position: absolute;
        width: 100%;
        height: 60px;
        line-height: 60px;
        & p {
            position: relative;
            bottom: 36px;
        }
    }
}

.table__totals__cell {
    text-align: right;
    &.fixed {
        padding: 0 !important;
    }
}
.table__cell.table__head__cell {
    @include no_select;
    padding: 8px 10px !important;
    cursor: pointer;
    font-weight: 500;
    color: $text-default;
}
.table__head__cell .fa-arrow-up {
    transition: 0.2s ease-in-out;
    position: absolute;
    left: 10px;
    top: calc(50% - 6px);
    transform: rotate(0deg);
    display: inline;
    vertical-align: middle;
    opacity: 0;
    height: 12px;
    width: 12px;
    background-image: url('/assets/images/icons/icon-arrowup-black.svg');
    background-size: contain;
    background-repeat: no-repeat;
}
.table__head__cell .fa-arrow-up.desc {
    transform: rotate(180deg);
}
.table__head__cell .fa-arrow-up.visible {
    opacity: 0.5;
}
.table__head__cell-text {
    display: inline;
}

.table__row {
    width: 100%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    position: relative;
}
.table__row:hover .table__cell {
    background-color: $hover-grey !important;
}
.table__row.selected .table__cell {
    background-color: $selection-grey !important;
}

.table__cell {
}
.tooltip > .tooltip-inner {
    padding: 10px;
    font-size: 12px;
    transition: opacity 0.2s ease-in-out;
    visibility: visible;
    opacity: 1;
    position: absolute;
    background-color: $off-black;
    color: $light;
    top: 0;
    right: 10px;
    width: 210px;
    border-radius: 5px;
    text-align: center;
}
.tooltip.top > .tooltip-arrow {
    border-top: 5px $off-black;
}
.table__cell,
.table__head__cell {
    line-height: 17px;
    font-size: 13px;
    border: 1px solid $border-grey;
    position: relative;
    transition: 0.2s ease-in-out;
    background-color: $white;
    background-clip: padding-box;
    // font-size: 12px;

    & .column-info {
        padding: 0;
        margin: 0;
        z-index: 1;

        & .tooltip__circle {
            background-color: $shadow-grey;
            float: right;
            box-shadow: none;
            &:hover {
                background-color: $off-black;
            }
        }
        & .tooltip__help-text {
            background-color: $off-black;
            position: absolute;
            z-index: 1;
            top: 24px;
            left: 25%;
            @include box-shadow(1);
        }
        &--offset-left .tooltip__help-text {
            left: auto;
            right: 0;
        }
    }
}

.table__cell > div {
    padding: 3px;
    font-size: 13px;
}

.table__cell > div > span,
.table__cell > div > a {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table__cell > div > a {
    color: $link;
    text-decoration: none;
}
.table__cell > div > a:hover {
    text-decoration: underline;
}

.table__cell__display-text {
    position: relative;
    width: 100%;
    text-align: right;
    display: flex;
    &.data-left {
        text-align: left !important;
    }
    &.red {
        color: $red;
    }
    &.amber {
        color: $orange;
    }
    &.green {
        color: $green;
    }
}

.table__cell__proportion {
    float: right;
    min-width: 60px;
    text-align: right;
}

.table__cell__display-text.dimension {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.loading {
        text-transform: italic;
    }
}

.table__cell__pct {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
}
.table__cell__pct-text {
    line-height: 22px;
    padding: 2px 6px;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.2s ease-in-out;
    &.always {
        opacity: 0.75;
    }
}

.table__cell__pct:hover .table__cell__pct-text {
    opacity: 1;
}
.table__cell__pct-bar {
    opacity: 0.2;
    background-color: $green;
    width: 0%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: 0.2s ease-in-out;
}
.table__cell__heatmap-bar {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    &.green {
        background-color: $green;
    }
    &.amber {
        background-color: $orange;
    }
    &.red {
        background-color: $red;
    }
}

// Fixed first column
.table__holder.scrolled .table__cell.table__head__cell.fixed,
.table__holder.scrolled .table__cell.table__totals__cell.fixed,
.table__holder.scrolled .table__cell.fixed {
    position: sticky;
    left: 0;
    border: 1px solid $border-grey;
    box-shadow: 3px 0 4px rgba(0, 0, 0, 0.16);
    z-index: 2;
}

.table__head__cell.fixed.dimension {
    min-height: 58px;
}
.table__search {
    position: absolute;
    width: 260px;
    right: 12px;
}

.table__search .bread-crumb__search-bar {
    background-color: transparent;
    & .bread-crumb__search-bar__indent {
        display: none;
    }
    & input {
        border-bottom: 1px solid $border-grey;
        color: $text-default;
        &::placeholder {
            color: $text-default;
            opacity: 0.8;
            font-weight: 400;
        }
        &:focus {
            color: $text-dark;
            outline: none;
        }
    }
}

.table-skeleton {
    &__table {
        width: 100%;
        margin: 6px 0;
        height: 198px;
        border-radius: 12px;
        @include skeletonLoader;

        /* added to center the info box */
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
