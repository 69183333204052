.list-item__title-copy-link {
    cursor: pointer;
    padding: 25px 20px;
    background-color: $white;
    border-bottom: 1px solid $light-grey;
    position: relative;
    &:last-child {
        border-bottom: none;
    }

    &__content {
        display: inline-block;
        width: calc(100% - 50px);
        & h3 {
            margin: 0 0 0.5em 0;
            color: $text_default;
        }
        & p {
            margin-bottom: 0;
            color: $text_default;
        }
    }

    & .icon {
        @include transition(background-color, 0.3s, ease-in-out);
        background-color: transparent;
        display: inline-block;
        width: 50px;
        text-align: center;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        & svg {
            @include transition(fill, 0.3s, ease-in-out);
            width: 50px;
            height: 30px;
            fill: $light-grey;
        }
    }

    &:hover {
        @include transition(background-color, 0.3s, ease-in-out);
        background-color: darken($light, $amount: 5);
        & svg {
            @include transition(fill, 0.3s, ease-in-out);
            fill: $text_default;
        }
    }
}
