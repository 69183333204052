.tab-list {
    display: inline-block;
    position: relative;
    user-select: none;
    bottom: 1px;
    margin-bottom: 20px;
}
.tab-list__item {
    user-select: none;
    margin-right: 6px;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    padding: 0 16px;
    line-height: 36px;
    min-width: 48px;
    display: inline-block;
    position: relative;
    color: $text-default;
    border-radius: 8px 8px 0 0;
    background-image: $tab-gradient;
    transition: 0.2s ease-in-out;
    border: 1px solid $border-grey;
    border-bottom: 1px solid white;
    top: 2px;
}
.tab-list__item.selected {
    background-image: none;
    background-color: white;
    border-bottom: 1px solid white;
}
.tab-list__item:hover {
    opacity: 0.76;
    color: $text-dark;
}
.tab-list__item:active {
    opacity: 0.68;
    color: black;
}

.tab-skeleton {
    width: 50%;
    display: inline-block;
    &__tabs {
        height: 32px;
        position: relative;
        &__tab {
            display: inline-block;
            position: relative;
            height: 100%;
            width: calc(25% - 12px);
            margin: 0 6px;
            border-radius: 12px;
            @include skeletonLoader;
        }
    }
}
