.message {
    padding: 12px;
    text-align: center;
    &__icon {
        margin-bottom: 20px;
        display: inline-block;
        & svg {
            height: 100px;
        }
    }
    &__text {
        display: inline-block;
        transform: translateY(calc(-52px - -50%));
        margin-left: 20px;
        text-align: left;
        max-width: 600px;
        width: 100%;
        & h3 {
            margin: 0 0 0.3em 0;
        }
        & p {
            margin: 0;
        }
    }

    &.small {
        & .message__icon {
            & svg {
                height: 80px;
            }
        }
        & .message__text {
            transform: translateY(calc(-40px - -50%));
            white-space: pre-line;
        }
    }
}
