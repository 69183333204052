#force-update-details__view {
    position: relative;
    height: 100vh;
    & img {
        height: 100%;
        width: 100vw;
        object-fit: cover;
        object-position: top;
        display: block;
    }
    & .force-update-details__content {
        position: absolute;
        text-align: center;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        & .icon-arrow {
            display: inline-block;
            margin-right: 10px;
            & svg {
                fill: $primary;
                height: 15px;
            }
        }
        & h3 {
            margin-top: 0;
            color: $primary;
            display: inline-block;
        }
        & img {
            height: 50px;
            width: auto;
            object-fit: initial;
            object-position: initial;
            margin-bottom: 15px;
            display: initial;
        }
        &__form {
            @include dropShadow(5px, 5px, 3px, 0px, rgba(0, 0, 0, 0.1));
            text-align: left;
            background-color: #ffffff;
            border-radius: 2px;
            padding: 20px;
            width: 100%;
            max-width: 500px;
            & .form-field {
                margin-bottom: 10px;
                padding-right: 0px;
            }

            &__error {
                margin-bottom: 10px;
                & span {
                    color: $red;
                    font-size: 0.9em;
                    margin-top: 0;
                    font-weight: 300;
                }
            }
        }
    }
}
