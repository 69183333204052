.tagging-guide {
    &__sidebar {
        padding-right: 30px;
        // overflow-y: scroll;
        max-height: calc(100vh - 140px);
        position: fixed;
        width: 25%;
        @media screen and (max-width: $screen-xl) {
            width: 33.33333%;
        }
        @media screen and (max-width: $screen-lg) {
            width: 41.66667%;
        }
        @media screen and (max-width: $screen-md) {
            position: relative;
            width: 100%;
            padding-right: 0px;
            & .side-bar__menu {
                display: none;
            }
        }

        & .bread-crumbs__search {
            margin-bottom: 30px;
        }
    }
}
