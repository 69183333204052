.journeys__filters {
    @include box_shadow(1);
    background-color: white;
    padding: 6px;
    margin-bottom: 6px;

    &__container {
        max-width: initial;

        &__title {
            display: block;
            margin: 0px 6px 6px 0px;
            font-size: 1.1rem;
            font-weight: 400;
        }

        &__buttons {
            display: inline-flex;
            justify-content: flex-end;
            margin-bottom: 6px;
            margin-right: 18px;
        }

        &__filters {
            margin-top: 6px;
        }

        &__preview {
            &__wrapper {
                display: flex;

                position: relative;
                top: 50%;
                transform: translateY(-50%);
                & div {
                    margin-left: 43px;
                }
            }
            &__values {
                h3 {
                    margin-top: 0;
                    margin-left: 6px;
                    margin-bottom: 0;
                    display: block;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 11px;
                    color: $off-black-lighter;
                    font-weight: 600;
                }
                & p {
                    display: inline;
                    margin: 6px;
                }
            }

            &__accordion {
                color: #ffffff;
                display: flex;
                align-items: center;
                width: 200px;
                margin: 0px 6px;
                cursor: pointer;
                padding: 0 18px;
                background: $off-black-lighter;
                height: 40px;
                line-height: 40px;
                p {
                    margin: 0;
                }

                &.show {
                    & svg {
                        transform: rotate(180deg);
                        transition: $transition;
                        margin-top: 12px;
                        position: relative;
                        top: 2px;
                    }
                }

                & .icon {
                    transform: rotate(180deg);
                    padding-left: 6px;
                    fill: #ffffff;
                    & svg {
                        margin-top: 12px;
                        transition: $transition;
                    }
                }
            }
        }

        & .row {
            width: 100%;
        }

        &__tags {
            display: block;
        }
    }
    &__button-group {
        display: inline-block;
    }
}
