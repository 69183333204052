.loading-spindle {
    &.login {
        height: 36px;
        width: 75px;
        text-align: center;
    }
    &.light {
        color: $light;
    }
    &.dark {
        color: $dark;
    }
    & .icon {
        transform: translateY(5px);
        & svg {
            fill: #ffffff;
            @include spinAnimation(2s);
        }
    }
}
