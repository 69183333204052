.menu__upper {
    display: flex;
    justify-content: space-between;
    background-color: $white;
    padding: 24px 24px 6px 0px;
    min-height: 88px;
    z-index: 500000;
    &__welcome {
        display: flex;
        & h4 {
            color: $dark;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            line-height: 0px;
            margin-left: 20px;
        }
        &__description {
            margin: 10px 0px 0px 10px;
        }
    }
    &__user {
        padding-right: var(
            --removed-body-scroll-bar-size
        ); // stops the div 'shifting' when the scrollbar is hidden/visible
        text-align: right;
        height: 44px;
        & p {
            display: inline-block;
            text-transform: uppercase;
            transform: translateY(-85%);
            margin-right: 10px;
            color: $dark;
            font-weight: 400;
        }
        &__profile {
            position: relative;
            top: -1px;
            display: inline-block;
            &:hover {
                & .menu__upper__user__profile-menu {
                    visibility: visible;
                    opacity: 1;
                    transition: visibility 0s linear 0s, opacity 300ms;
                }

                & .icon-person {
                    & svg {
                        @include transition(color, 0.2s, ease-in-out);
                        color: $cubed-orange;
                    }
                }
            }
            & .icon-person {
                border-radius: 50%;
                cursor: pointer;

                & svg {
                    color: $off-black-lighter;
                    width: 42px;
                    height: auto;
                }
            }
            &-menu {
                @include dropShadow(5px, 5px, 3px, 0px, rgba(0, 0, 0, 0.1));
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s linear 300ms, opacity 300ms;
                text-align: left;
                position: absolute;
                top: 20px;
                right: 20px;
                width: 160px;
                background-color: $white;
                border-radius: 2px;
                & .icon {
                    display: inline-block;
                    height: 10px;
                    margin-right: 8px;
                    & svg {
                        fill: $text_default;
                    }
                }
                & a {
                    @include transition(background-color, 0.2s, ease-in-out);
                    color: $text_default;
                    text-decoration: none;
                    display: inline-block;
                    padding: 10px;
                    width: 100%;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover {
                        @include transition(background-color, 0.2s, ease-in-out);
                        background-color: darken($light, $amount: 5);
                    }
                }
            }
        }
        &__accounts {
            display: inline-block;
            position: relative;
            &:hover {
                & .menu__upper__user__accounts-menu {
                    visibility: visible;
                    opacity: 1;
                    transition: visibility 0s linear 0s, opacity 300ms;
                }
                .icon-ellipsis {
                    & svg {
                        @include transition(fill, 0.2s, ease-in-out);
                        fill: $cubed-orange;
                    }
                }
            }
            & .icon-ellipsis {
                @include transition(background-color, 0.2s, ease-in-out);
                top: -7px;
                position: relative;
                display: block;
                width: 44px;
                height: 44px;
                text-align: center;
                padding-top: 6px;
                border-radius: 30px;
                cursor: pointer;

                & svg {
                    color: $off-black-lighter;
                    height: 30px;
                }
            }
            &-menu {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s linear 300ms, opacity 300ms;
                position: absolute;
                top: 15px;
                right: 22px;
                width: 400px;
                padding: 20px;
                background-color: $white;
                text-align: left;
                color: $text_default;
                @include dropShadow(5px, 5px, 3px, 0px, rgba(0, 0, 0, 0.1));
                border-radius: 2px;
                & .list-widget {
                    overflow-y: scroll;
                    height: 300px;
                    margin-bottom: 0;
                }
                // height: 300px;
                & .input-field {
                    width: 100%;
                    padding: 10px 5px;
                    font-size: 16px;
                    font-weight: 400;
                    max-width: initial;
                    border-radius: initial;
                    background-color: initial;
                    border: solid 1px $text_default;
                    margin-bottom: 20px;
                }
                & p {
                    transform: initial;
                    display: block;
                }
            }
        }
    }

    &.light {
        background-color: $white;
        color: $primary;

        & .menu__upper__user__accounts {
            &:hover {
                & .icon-ellipsis {
                    background-color: $input_colour;
                }
            }
            & .icon-ellipsis {
                & svg {
                    fill: $off-black;
                }
                &:hover {
                    background-color: $input_colour;
                }
            }
        }
    }
}
