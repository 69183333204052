.list-item__body {
    background-color: $text_light;
    padding: 25px 20px;

    h3 {
        margin-top: 0;
    }

    p {
        margin-bottom: 10px;
    }
}
