.checkbox {
    padding: 0 2px;
    background-color: #afafaf;
    width: 18px;
    height: 18px;
    position: absolute;
    opacity: 1;
    transition: $transition;
    border-radius: 3px;
    left: 0;
    right: 0;
    &.selected {
        background-color: darken($off-black, 12);
        border-radius: 20px;
    }
    &:hover {
        opacity: 0.8;
    }
    &.disabled {
        cursor: not-allowed;
        background-color: #e1e1e1;
    }
    &__holder {
        width: 12px;
        position: relative;
    }
    &__tick {
        position: relative;
        left: 2px;
        top: -1px;
        opacity: 1;
        transform: scale(1);
        transition: 0.2s ease-in;
        visibility: visible;
        &.hidden {
            pointer-events: none;
            opacity: 0;
            transform: scale(7);
            transition: 0.4s ease-out;
            visibility: hidden;
            top: -16px;
        }
    }
}
