.colour-picker {
    position: relative;
    &__select {
        width: 100%;
        height: 40px;
        background-color: $input_colour;
        border: none;
        border-bottom: solid 2px $off-black;
        margin-bottom: 5px;
        border-radius: 2px;
        cursor: pointer;
        &__colour-preview {
            width: 40px;
            height: 40px;
            display: table-cell;
            &.empty {
                background-image: linear-gradient(
                    135deg,
                    $light 0%,
                    $light 48%,
                    $red 48%,
                    $red 52%,
                    $light 52%,
                    $light 100%
                );
                background-size: 40px 40px;
            }
        }
        & p {
            display: inline-block;
            display: table-cell;
            padding: 10px 12px;
            font-weight: 400;
            &.placeholder {
                font-weight: 300;
                font-size: 0.9em;
                padding: 12px;
            }
        }
    }
    &__picker {
        @include dropShadow(5px, 5px, 3px, 0px, rgba(0, 0, 0, 0.1));
        @include transition(opacity, 0.2s, ease-in-out);
        visibility: hidden;
        opacity: 0;
        position: absolute;
        height: auto;
        z-index: 5;
        width: 100%;
        max-width: 310px;
        margin-left: 40px;
        background-color: $white;
        padding: 2px;
        margin-top: -5px;
        &__colour-tile {
            @include transition(filter, 0.2s, ease-in-out);
            height: 40px;
            flex: 0 0 calc(16.66667% - 4px);
            max-width: calc(16.66667% - 4px);
            display: inline-block;
            margin: 2px;
            cursor: pointer;
            &:hover {
                filter: saturate(0.5);
            }
        }
    }
    &:hover {
        & .colour-picker__picker {
            @include transition(opacity, 0.2s, ease-in-out);
            visibility: visible;
            opacity: 1;
        }
    }
}
