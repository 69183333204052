.simple-table {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 2px;

    &__error {
        margin-top: 20px;
    }

    &__table {
        width: 100%;
        text-align: left;

        & tbody {
            display: block;
            height: 100%;
            overflow-y: auto;
        }

        &__header {
            border-bottom: solid 1px $light-grey;
            display: table;
            width: 100%;
            table-layout: fixed;
            cursor: initial;

            &not:first-child {
                border-left: solid 1px $light-grey;
            }

            & h4 {
                font-size: 1rem;
                font-weight: 500;
                color: #0a0a0a;
                padding: 0 5px 7px 5px;
                margin: 0;
                display: inline-block;
                &.orderable {
                    cursor: pointer;
                }
            }
            & .fa-arrow-up {
                display: none;
                position: initial;
                left: initial;
                top: initial;
                transform: rotate(0deg);
                vertical-align: initial;
                visibility: visible;
                &.visible {
                    opacity: 0.5;
                    display: inline-block;
                }
                &.desc {
                    transform: rotate(180deg);
                }
            }
        }

        &__row {
            border-bottom: solid 1px $light-grey;
            background-color: #ffffff;
            cursor: pointer;
            display: table;
            width: 100%;
            table-layout: fixed;

            &:hover {
                @include transition(background-color, 0.3s, ease-in-out);
                background-color: $light-grey;
            }

            &.selected {
                background-color: $input-colour;
                &:hover {
                    background-color: $input-colour;
                }
            }

            &.indented {
                & td:first-child {
                    padding-left: 32px;
                    word-wrap: break-word;
                }
            }

            &.dragOver {
                background-color: $input-colour;
            }
            &.warning {
                & td {
                    position: relative;
                    &:first-child {
                        &::before {
                            content: '';
                            background-image: url(/assets/images/icons/error-icon.png);
                            height: 24px;
                            width: 24px;
                            top: 50%;
                            left: 3px;
                            transform: translateY(-50%);
                            background-size: 24px;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                        }
                    }
                }
            }
            &.deleted {
                & td {
                    position: relative;
                    &:first-child {
                        &::before {
                            content: '';
                            background-image: url(/assets/images/icons/deleted.png);
                            height: 24px;
                            width: 24px;
                            top: 50%;
                            left: 3px;
                            transform: translateY(-50%);
                            background-size: 24px;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                        }
                    }
                }
            }
            &.revoke-user {
                & td {
                    position: relative;
                    &:first-child {
                        &::before {
                            content: '';
                            background-image: url(/assets/images/icons/revoke-user.png);
                            height: 24px;
                            width: 24px;
                            top: 50%;
                            left: 3px;
                            transform: translateY(-50%);
                            background-size: 24px;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                        }
                    }
                }
            }
            &.edited {
                & td {
                    position: relative;
                    &:first-child {
                        &::before {
                            content: '';
                            background-image: url(/assets/images/icons/edited.png);
                            height: 24px;
                            width: 24px;
                            top: 50%;
                            left: 3px;
                            transform: translateY(-50%);
                            background-size: 24px;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                        }
                    }
                }
            }
            &.added {
                & td {
                    position: relative;
                    &:first-child {
                        &::before {
                            content: '';
                            background-image: url(/assets/images/icons/added.png);
                            height: 24px;
                            width: 24px;
                            top: 50%;
                            left: 3px;
                            transform: translateY(-50%);
                            background-size: 24px;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                        }
                    }
                }
            }
            &.success {
                & td {
                    position: relative;
                    &:last-child {
                        &::before {
                            content: '';
                            background-image: url(/assets/images/icons/check-circle.svg);
                            height: 24px;
                            width: 24px;
                            top: 50%;
                            left: 75%;
                            transform: translateY(-50%);
                            background-size: 24px;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                        }
                    }
                }
            }
            &.error {
                & td {
                    position: relative;
                    &:last-child {
                        &::before {
                            content: '';
                            background-image: url(/assets/images/icons/times-circle.svg);
                            height: 24px;
                            width: 24px;
                            top: 50%;
                            left: 75%;
                            transform: translateY(-50%);
                            background-size: 24px;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                        }
                    }
                }
            }
            &.disabled {
                cursor: not-allowed;
                &:hover {
                    background-color: #ffffff;
                }
                &.selected {
                    background-color: $input-colour;
                }
            }
            &.locked {
                & td {
                    position: relative;
                    cursor: not-allowed;
                    &:first-child {
                        &::before {
                            content: '';
                            background-image: url(/assets/images/icons/locked.png);
                            height: 24px;
                            width: 24px;
                            top: 50%;
                            left: 3px;
                            transform: translateY(-50%);
                            background-size: 24px;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                        }
                    }
                }
            }
            &.child {
                & .simple-table__table__row__column {
                    &:first-child {
                        padding-left: 30px;
                    }
                }
                &.indented {
                    & .simple-table__table__row__column {
                        &:first-child {
                            padding-left: 60px;
                        }
                    }
                }
            }
            &not:first-child {
                border-left: solid 1px $light-grey;
            }

            &.notSelectable {
                cursor: initial;
                &:hover {
                    background-color: #ffffff;
                }
            }

            &__column {
                &.indented:first-child {
                    padding-left: 32px;
                }

                &.toggle {
                    padding: 6px 0;
                }
                & p {
                    padding: 5px;
                    margin: 0;
                    overflow: hidden;
                    white-space: pre;
                    text-overflow: ellipsis;
                    border-radius: 8px;
                    transition: width 0.4s ease;
                }

                &.order-icon {
                    width: 25px;
                    & .icon-order {
                        padding: 3px 6px;
                        & svg {
                            height: 12px;
                            fill: $text_default;
                        }
                    }
                }
            }
        }

        &__totals-row {
            font-size: 1.2rem;
            font-weight: 500;
            padding-left: 5px;
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            margin: 0;
        }

        &__totals-label {
            margin-right: 2rem;
            text-align: right;
        }
    }
}
