@import './../bmixins'; //Import all sass snippets
@import './../global'; // Import Theme Globals

.add-item {
    position: relative;
    &__dropdown {
        overflow-y: scroll;
        overflow-x: hidden;
        list-style: none;
        border: 1px solid $shadow-grey;
        margin: 0 6px 6px 6px;
        max-height: 290px;
        &.hidden {
            max-height: 0;
        }
        &__wrapper {
            padding: 12px 0;
            background-color: $light;
            overflow: hidden;
            @include box-shadow(1);
            max-height: 380px;
            width: 100%;
            position: absolute;
            z-index: 99999;
            &.hidden {
                padding: 0;
                max-height: 0;
            }
        }

        &__item {
            border: none;
            color: $text_dark;
            position: relative;
            cursor: pointer;
            width: 100%;
            background-color: $hover-grey;
            padding: 4px 12px;
            border-radius: 3px;
            margin-bottom: 2px;
            transition: 0.2s ease-in;
            &:hover {
                background-color: darken($hover-grey, 6);
            }
        }
    }
    &__close-btn {
        border: none;
        margin-bottom: 0;
    }
}

.add-items {
    $breadcrumb-height: 48px;
    $breadcrumb-label-height: 11px;
    $breadcrumb-content-height: $breadcrumb-height - $breadcrumb-label-height;
    text-transform: uppercase;
    margin-left: 22px;
    margin-top: -12px;
    display: inline-block;
    &__item {
        position: relative;
        box-sizing: border-box;
        float: right;
        margin-left: -20px;
        &__current__cancel {
            color: $danger;
            background-color: white;
            padding: 6px;
            border-radius: 24px;
            margin: 0 24px;
        }
        &__label {
            height: $breadcrumb-label-height;
            line-height: $breadcrumb-label-height;
            position: relative;
            padding-left: 24px;
            font-size: $breadcrumb-label-height;
            font-weight: 600;
            letter-spacing: 2px;
            padding-top: 4px;
            text-transform: uppercase;
            color: $primary;
            &.dropdown {
                padding-left: 0;
            }
        }
        &__content {
            @include edge($light-grey);
            line-height: $breadcrumb-content-height;
            height: $breadcrumb-content-height;
            min-width: $breadcrumb-height + 6;
            padding-left: $breadcrumb-height / 2;
            position: relative;
            border-radius: 0 $breadcrumb-content-height $breadcrumb-content-height 0;
            background-color: $light-grey;
            overflow: hidden;

            display: flex;
            flex-direction: row;
            justify-content: left;
            &__wrapper {
                border-radius: 0 $breadcrumb-content-height $breadcrumb-content-height 0;
                background-color: $white;
                padding: 6px;
                display: block;
                width: 100%;
                height: 100%;

                &.not-allowed {
                    cursor: not-allowed;
                    pointer-events: all !important;
                }
            }
            &.home {
                padding-left: 0;
            }
            & > span {
                padding-right: 24px;

                &.dropdown__button {
                    cursor: pointer;
                    $arrow-size: 12px;
                    padding: $breadcrumb-content-height / 2 - $arrow-size / 2;
                    background-color: $primary;
                    transition: $transition;
                    @include hover($primary);
                    & .dropdown__button__icon {
                        display: inline-block;
                        margin-left: 0;
                        width: $arrow-size;
                        height: $arrow-size;
                        background-image: url('/assets/images/icons/drop-down-arrow.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
            }
            &.current {
                @include edge($primary);
                background-color: $primary;
                color: $light;
            }
        }
        &__home {
            cursor: pointer;
            background-image: url('/assets/images/icons/home.svg');
            background-size: 24px;
            background-repeat: no-repeat;
            background-position: 12px 6px;
            height: 36px;
        }
        &__dropdown {
            @include box_shadow(1);
            visibility: hidden;
            max-height: 0;
            position: absolute;
            background-color: $light;
            transition: $transition;
            z-index: 1000;
            width: 100%;
            padding: 6px;
            overflow: hidden;
            & ul {
                overflow-x: hidden;
                & li {
                    position: relative;
                    cursor: pointer;
                    width: 100%;
                    background-color: $hover-grey;
                    padding: 4px 12px;
                    border-radius: 3px;
                    margin: 2px;
                    transition: 0.2s ease-in;
                    &:hover {
                        background-color: darken($hover-grey, 6);
                    }
                    &.loading {
                        cursor: default;
                        &:hover {
                            background-color: $hover-grey;
                        }
                    }
                }
            }
            &.open {
                visibility: visible;
                max-height: 600px;
            }
            &.current {
                $dropdown-width: 240px;
                right: 28px - $dropdown-width;
                top: $breadcrumb-content-height - 6px;
                min-width: $dropdown-width;
                overflow-y: auto;
            }
        }
        & input {
            box-sizing: border-box;
            padding-left: 6px;
            margin-right: $breadcrumb-content-height;
            margin-top: 1px;
            line-height: $breadcrumb-content-height - 6px - 4px;
            border-radius: 0 $breadcrumb-content-height $breadcrumb-content-height 0;
            background-color: transparent;
            transition: $transition;
            border: 1px solid transparent;
            max-width: 120px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            transition: $transition;
            &:focus {
                max-width: 260px;
                background-color: rgba($light, 0.6);
                border: 1px solid $info;
            }
        }
        &__event {
            &:disabled {
                cursor: not-allowed;
                pointer-events: all !important;
            }
            &::-webkit-input-placeholder {
                /* Edge */
                color: #58b5c4;
                font-size: 14px;
                font-weight: 300;
            }
            &:-ms-input-placeholder {
                /* Internet Explorer */
                color: #58b5c4;
                font-size: 14px;
                font-weight: 300;
            }
            &::placeholder {
                color: #58b5c4;
                font-size: 14px;
                font-weight: 300;
            }
        }
    }
}

.clear-float {
    float: none;
}
