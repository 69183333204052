.pill {
    padding: 3px 12px;
    background-color: $light-grey;
    display: inline-block;
    margin: 6px 3px 6px 0;
    border: solid 1px $light-grey;
    border-radius: 3px;
    vertical-align: bottom;

    & p {
        font-size: 1em;
        padding: 0px;
        max-width: 70px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & p:hover {
        max-width: unset;
        cursor: default;
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
    }
    &.start-piece {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    &.end-piece {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    &.active {
        border-color: $primary;
        background-color: $input-colour;
    }
    &.is-more {
        padding: 0 4px 0 4px;
        border-radius: 0px;
        height: 30px;
        & p {
            color: #cccccc;
        }
    }
}

.path {
    & p {
        max-width: 250px !important;
    }
    & p:hover {
        max-width: unset !important;
    }
}
