/* work-sans-100 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 100;
    src: url('../assets/fonts/work-sans-v19-latin-100.woff2') format('woff2');
}
/* work-sans-100italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 100;
    src: url('../assets/fonts/work-sans-v19-latin-100italic.woff2') format('woff2');
}
/* work-sans-200 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 200;
    src: url('../assets/fonts/work-sans-v19-latin-200.woff2') format('woff2');
}
/* work-sans-200italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 200;
    src: url('../assets/fonts/work-sans-v19-latin-200italic.woff2') format('woff2');
}
/* work-sans-300 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/work-sans-v19-latin-300.woff2') format('woff2');
}
/* work-sans-300italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 300;
    src: url('../assets/fonts/work-sans-v19-latin-300italic.woff2') format('woff2');
}
/* work-sans-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/work-sans-v19-latin-regular.woff2') format('woff2');
}
/* work-sans-italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/work-sans-v19-latin-italic.woff2') format('woff2');
}
/* work-sans-500 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/work-sans-v19-latin-500.woff2') format('woff2');
}
/* work-sans-500italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 500;
    src: url('../assets/fonts/work-sans-v19-latin-500italic.woff2') format('woff2');
}
/* work-sans-600 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/work-sans-v19-latin-600.woff2') format('woff2');
}
/* work-sans-600italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 600;
    src: url('../assets/fonts/work-sans-v19-latin-600italic.woff2') format('woff2');
}
/* work-sans-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/work-sans-v19-latin-700.woff2') format('woff2');
}
/* work-sans-700italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 700;
    src: url('../assets/fonts/work-sans-v19-latin-700italic.woff2') format('woff2');
}
/* work-sans-800 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 800;
    src: url('../assets/fonts/work-sans-v19-latin-800.woff2') format('woff2');
}
/* work-sans-800italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 800;
    src: url('../assets/fonts/work-sans-v19-latin-800italic.woff2') format('woff2');
}
/* work-sans-900 - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    src: url('../assets/fonts/work-sans-v19-latin-900.woff2') format('woff2');
}
/* work-sans-900italic - latin */
@font-face {
    font-display: swap;
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 900;
    src: url('../assets/fonts/work-sans-v19-latin-900italic.woff2') format('woff2');
}

@mixin edge($colour) {
    border: none;
    border-top: 1px solid lighten($colour, 12);
    border-bottom: 1px solid darken($colour, 12);
}

@mixin hover($colour) {
    &:hover {
        background-color: darken($colour, 6);
    }
    &:active {
        background-color: darken($colour, 12);
    }
}

@mixin box_shadow($level) {
    @if $level == 1 {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    } @else if $level == 2 {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
    } @else if $level == 3 {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    } @else if $level == 4 {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    } @else if $level == 5 {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    } @else if $level == 0 {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.05);
    } @else if $level == 0.5 {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.18);
    }
}

@mixin no_select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

$screen-xs: 0;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

// Brand Colours
$cubed-orange: #ff7300;

$primary: #58b5c4;
$secondary: #dadada;
$danger: #d2004a;
$warning: #ff9800;
$success: #4caf50;
$info: #6ad9eb;
$link: #58b5c4;
$focus-colour: $info;

$primary-light: #aad2f3;
$primary-lighter: #cae3f6;

$light: #eeeeee;
$dark: #212121;
$red: #d2004a;
$orange: #ff9800;
$green: #4caf50;
$white: #ffffff;
$black: #000000;

$off-black: #0a0a0a;
$off-black-lighter: #1e1c1c;
$off-light: #f0f0f0;

$light-grey: #f1f1f1;
$shadow-grey: #cbcbcb;
$border-grey: #e4e4e4;
$hover-grey: #eeeeee;
$selection-grey: #e3e7e7;
$dark-grey: #8a8a8a;
$darker-grey: #4d4d4d;

$text_dark: #212121;
$text_default: #6f6f6f;
$text_light: #ffffff;

$input_colour: #e9f0f5;

$text_font-family: 'Work Sans', sans-serif;

$transition: 0.2s ease-in-out;
$tab-gradient: linear-gradient(#eaeaea, #e3e3e3, #e3e3e3, #e3e3e3, #cdcdd1);

$scrollbarwidth: 8px;
$scrollbarheight: 8px;
.scrollbar::-webkit-scrollbar {
    width: $scrollbarwidth;
    height: $scrollbarheight;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.12);
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.26);
}
.scrollbar--dark::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.75);
}

.clear {
    clear: both;
}
.clickable {
    cursor: pointer;
}

.italic {
    font-style: italic;
}

button:focus,
input:focus {
    outline: none;
}

@mixin skeletonLoader {
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: skeletonGradient;
    animation-timing-function: linear;
    background: linear-gradient(to right, $light-grey, $shadow-grey, $light-grey);
    background-size: 100vw 100vw;
}

@keyframes skeletonGradient {
    0% {
        background-position: -100vw 50%;
    }
    100% {
        background-position: 100vw 50%;
    }
}

.contribution-tooltip {
    background-color: $off-black;
    color: $white;
    padding: 5px 10px;
    width: fit-content;
    font-size: 0.8rem;
    border-radius: 3px;
}

table {
    font-variant-numeric: tabular-nums;
}
