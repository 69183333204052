.manage-modals {
    & .simple-table__table {
        &__header {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
        &__row {
            display: table;
            width: 100%;
            table-layout: fixed;
        }

        & tbody {
            display: block;
            max-height: 250px;
            overflow-y: scroll;
        }
    }

    & .accordion {
        & .simple-table__table {
            &__header {
                & .simple-table__table__row__column {
                    &:nth-child(2) {
                        width: 140px;
                    }
                    &:nth-child(3) {
                        width: 140px;
                    }
                }
            }
            &__row {
                &:hover {
                    @include transition(background-color, 0.3s, ease-in-out);
                    background-color: $light-grey;
                }
                &__column {
                    &.toggle {
                        width: 140px;
                    }
                }
            }
        }
    }

    & .csv-download {
        margin: 0 0px 0 12px;
        &__button {
            &__wrapper {
                background-color: white;
            }
        }
    }
}
