.journeys {
    &-skeleton {
        &__filters {
            @include box_shadow(1);
            background-color: white;
            padding: 6px;
            margin-bottom: 6px;
            height: 54px;
            &__item {
                @include skeletonLoader;
                display: inline-block;
                height: 42px;
                max-width: 250px;
                width: 100%;
                border-radius: 12px;
                margin-right: 12px;
            }
        }

        &__container {
            @include box_shadow(1);
            background-color: white;
            padding: 6px;
            margin-bottom: 6px;
            max-width: initial;
            &__breadcrumbs__item {
                @include skeletonLoader;
                display: inline-block;
                height: 42px;
                max-width: 400px;
                width: 100%;
                border-radius: 12px;
                margin-bottom: 18px;
            }
            &__table-graph {
                &__table__item {
                    @include skeletonLoader;
                    display: inline-block;
                    height: 48px;
                    width: 100%;
                    border-radius: 12px;
                    margin-bottom: 3px;
                    &-header {
                        @include skeletonLoader;
                        display: inline-block;
                        height: 60px;
                        width: 100%;
                        border-radius: 6px;
                        margin-bottom: 6px;
                    }
                }
                &__graph {
                    position: relative;
                    &__item {
                        @include skeletonLoader;
                        display: inline-block;
                        height: 500px;
                        width: 500px;
                        top: 50%;
                        left: 50%;
                        position: absolute;
                        transform: translate(-50%, -50%);
                        border-radius: 30vw;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }

    & .hiddenTooltip {
        display: none;
    }

    &__container {
        @include box_shadow(1);
        background-color: $text_light;
        padding: 12px 12px 0 12px;
        max-width: initial;
        min-height: 650px;
    }

    & .simple-table {
        & tbody {
            min-height: 650px;
            height: calc(100vh - 250px);
            max-height: initial;
        }

        &__table {
            &__header {
                & .simple-table__table__row__column {
                    &:nth-child(2) {
                        width: 130px;
                    }
                    &:nth-child(3) {
                        width: 130px;
                    }
                    &:nth-last-child(1) {
                        width: 145px;
                    }
                }
            }
            &__row {
                &__column {
                    &:nth-child(2) {
                        width: 130px;
                    }
                    &:nth-child(3) {
                        width: 130px;
                    }
                    &:nth-last-child(1) {
                        width: 130px;
                    }
                }
            }
        }
    }
}
