.min-max-select {
    &__error {
        color: $red;
        font-size: 0.9rem;
    }

    &__container {
        display: inline-block;

        & label {
            display: block;
            margin: 0px 6px 6px 0px;
            font-size: 1.1em;
            font-weight: 400;
        }

        & input {
            display: inline-block;
            max-width: 100px;
            margin-right: 6px;
        }
    }
}
