.icon {
    & svg {
        height: 20px;
    }
}
.icon.icon-yard-logo {
    position: relative;
    width: 100%;
    height: 45px;
    background: url('/assets/images/white-cubed-logo-full.png') no-repeat center center;
    top: -6px;
}
