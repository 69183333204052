.side-panel__header {
    position: relative;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    min-height: 33vh;
    background-color: $primary;
    & .side-panel__header-items {
        bottom: 0;
        position: absolute;
        & .side-panel__header-logo {
            position: relative;
            width: 100%;
            height: 64px;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            margin-bottom: 32px;
            margin-top: 32px;
            & a {
                text-decoration: none;
                color: $text_light;
                & span {
                    font-size: 1.4em;
                    text-transform: none;
                    font-weight: 600;
                    padding-left: 64px;
                }
                & img {
                    position: absolute;
                    width: 40px;
                    height: auto;
                    max-width: 40px;
                    max-height: 40px;
                    top: 12px;
                }
            }
        }
        & .side-panel__header-title {
            width: 100%;
            letter-spacing: 0rem;
            font-weight: 400;
            text-transform: inherit;
            margin: 0 0 10px 0;
            margin-bottom: 1rem;
            color: $text_light;
        }
    }
}
