.rdDay {
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
}

@supports (-ms-ime-align: auto) {
    .rdDay {
        flex-basis: 14.285% !important;
    }
}

.rdDay {
    background: transparent;
    user-select: none;
    border: 0;
    padding: 0;
    line-height: 3em;
    height: 3em;
    text-align: center;
    width: 25%;
    color: #1d2429;
    &:focus {
        outline: 0;
    }
}

.rdDay:not(.rdrDayPassive) {
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected {
        & ~ .rdrDayNumber {
            span {
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }
}
