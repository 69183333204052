/*!
 * Bootstrap Grid v4.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
    @-ms-viewport {
        width: device-width;
    } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

@import 'functions';
@import 'variables';

@import 'mixins/breakpoints';
@import 'mixins/grid-framework';
@import 'mixins/grid';

@import 'grid';
@import 'utilities/display';
@import 'utilities/flex';
