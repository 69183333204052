.input-field {
    &__button {
        & button {
            cursor: pointer;
            border: none;
            min-width: 40px;
            padding: 0 16px;
            border-radius: 2px;
            font-size: 14px;
            line-height: 36px;
            margin: 0;
            text-transform: uppercase;
            font-weight: 500;
            text-decoration: none;
            color: #ffffff;
            background-color: $off-black;
            text-align: center;
            position: relative;
            &:disabled {
                background-color: transparentize($off-black, 0.4);
                cursor: not-allowed;
            }
        }
        & .loading-spindle {
            color: #ffffff;
            background-color: $off-black-lighter;
            text-align: center;
            position: relative;
            width: fit-content;
            padding: 0 16px;
            border-radius: 2px;
            line-height: 36px;
            margin: 0;
        }

        &.secondary {
            & button {
                background-color: transparent;
                color: $text_default;
            }
            & .loading-spindle {
                background-color: transparent;
                color: $text_default;
            }
        }

        &.test-up {
            position: relative;
            bottom: 172px;
            left: 175px;
            & button {
                background-color: $green;
                color: $black;
            }
            & .loading-spindle {
                background-color: $green;
                color: $black;
            }
        }

        &.test-down {
            position: relative;
            bottom: 53px;
            left: 175px;
            & button {
                background-color: $green;
                color: $black;
            }
            & .loading-spindle {
                background-color: $green;
                color: $black;
            }
        }

        &.red {
            & button {
                background-color: $red;
                &:disabled {
                    background-color: transparentize($red, 0.4);
                    cursor: not-allowed;
                }
            }
            & .loading-spindle {
                background-color: $red;
            }
            &.secondary {
                & button {
                    color: $red;
                    background-color: transparent;
                    &:disabled {
                        color: transparentize($red, 0.4);
                    }
                }
                & .loading-spindle {
                    color: $red;
                    background-color: transparent;
                }
            }
        }
        &.green {
            & button {
                background-color: $green;
                &:disabled {
                    background-color: transparentize($green, 0.4);
                    cursor: not-allowed;
                }
            }
            & .loading-spindle {
                background-color: $green;
            }
            &.secondary {
                & button {
                    color: $green;
                    background-color: transparent;
                    &:disabled {
                        color: transparentize($red, 0.4);
                    }
                }
                & .loading-spindle {
                    color: $green;
                    background-color: transparent;
                }
            }
        }

        &.thin {
            & button {
                padding: 0 8px;
            }
        }

        &.list-builder__button {
            & button {
                background-color: $input_colour;
            }

            &.select {
                & button {
                    color: $green;
                }
                color: $green;
            }

            &.deselect {
                & button {
                    color: $red;
                }
            }
        }
    }
}
