.list-item__title-copy {
    cursor: pointer;
    padding: 25px 20px;
    background-color: $white;
    border-bottom: 1px solid $light-grey;
    position: relative;
    &:last-child {
        border-bottom: none;
    }

    &__content {
        display: inline-block;
        width: calc(100% - 50px);
        & h3 {
            margin: 0 0 0.5em 0;
        }
        & p {
            margin-bottom: 0;
        }
    }

    & .icon-container {
        @include transition(background-color, 0.3s, ease-in-out);
        background-color: transparent;
        display: inline-block;
        width: 50px;
        text-align: center;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        & .icon {
            float: right;
            svg {
                @include transition(fill, 0.3s, ease-in-out);
                height: 38px;
                fill: $light-grey;
            }
        }
    }

    &:hover {
        @include transition(background-color, 0.3s, ease-in-out);
        background-color: darken($light, $amount: 5) !important;
        & .icon {
            svg {
                @include transition(fill, 0.3s, ease-in-out);
                fill: $text_default;
            }
        }
    }
}
