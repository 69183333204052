//
@import './../bmixins'; //Import all sass snippets

@import './../global'; // Import Theme Globals

.sparkline {
    @include box_shadow(1);
    flex: 1 1 0;
    min-width: 0;
    margin: 6px;
    position: relative;
    &__title {
        margin: 12px;
        width: calc(100% - 24px);
        color: white;
        text-align: left;
    }
    &__line__loading {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        transition: $transition;
        visibility: hidden;
        &.loading {
            visibility: visible;
            opacity: 0.5;
        }
    }
    &__line__holder {
        position: relative;
        &__highcharts-wrapper {
            width: 100%;
            height: 10vh;
            margin: 0 auto;
        }
    }
    &__totals {
        padding: 12px;
        position: relative;
        width: 100%;
        background-color: $white;
        bottom: 0;
        overflow: hidden;
        &__no-data {
            width: 100%;
            font-size: 1.2vw;
            text-align: center;
            padding-bottom: 6px 0;
        }
        &__item {
            float: left;
            display: inline-block;
            position: relative;
            width: 50%;
            &__value {
                text-align: center;
                width: 100%;
                font-size: 1.1vw;
            }
            &__label {
                text-align: center;
                line-height: 12px;
                min-height: 12px;
                font-size: 12px;
            }
        }
    }
    &__skeleton {
        flex: 1 1 0;
        margin: 6px;
        position: relative;
        width: 100%;
        &__title {
            width: 100%;
            padding: 6px;
            margin: 6px;
            @include skeletonLoader;
        }
        &__chart {
            width: 100%;
            padding: 50px;
            margin: 6px;
            @include skeletonLoader;
        }
        &__footer {
            width: 100%;
            padding: 24px;
            margin: 6px;
            @include skeletonLoader;
        }
    }
}
