.reports {
    &__filters {
        @include box_shadow(1);
        width: 100%;
        padding: 6px;
        background-color: $white;
        margin-bottom: 6px;
        &.hidden {
            display: none;
        }
    }
}
.switch-wrapper {
    position: relative;
}

.switch-wrapper > div {
    position: absolute;
    width: 100%;
}
