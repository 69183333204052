.date-picker {
    position: relative;
    float: left;
    z-index: 10;

    &__preview {
        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
        float: right;
        position: relative;
        font-weight: 300;
        color: $white;
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        box-sizing: border-box;
        cursor: pointer;
        padding: 0 18px;
        background-color: $off-black-lighter;
        &__holder {
            overflow: hidden;
        }
        & .icon-calendar {
            display: inline-block;
            margin: 3px 12px 5px 0;

            & svg {
                width: 18px;
                height: 18px;
                fill: $light;
            }
        }

        & p {
            display: inline-block;
            margin-bottom: 0px;
            transform: translateY(-3px);
        }
    }

    &__picker {
        @include box_shadow(3);
        top: 36px;
        position: absolute;
        background-color: $white;
        padding: 0 0 12px 0;

        &__calendar {
            border-radius: 2px;
            position: relative;
        }

        & .rdrInputRanges {
            display: none;
        }

        .live-btn {
            float: left !important;
            padding-left: 20px;
        }

        .controller-btn {
            float: right !important;
        }
    }
}

.rdrDayToday span:after {
    background-color: $off-black-lighter !important;
}
