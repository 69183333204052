.icon-times {
    svg {
        cursor: pointer;
    }

    & .error {
        svg {
            color: $red;
        }
    }
}
