@mixin spinAnimation($duration) {
    -webkit-animation: spin $duration infinite linear; /* Safari 4+ */
    -moz-animation: spin $duration infinite linear; /* Fx 5+ */
    -o-animation: spin $duration infinite linear; /* Opera 12+ */
    animation: spin $duration infinite linear; /* IE 10+, Fx 29+ */

    @-webkit-keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-moz-keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}

@mixin enlarge($duration, $size) {
    -webkit-animation: scale $duration infinite linear; /* Safari 4+ */
    -moz-animation: scale $duration infinite linear; /* Fx 5+ */
    -o-animation: scale $duration infinite linear; /* Opera 12+ */
    animation: scale $duration infinite linear; /* IE 10+, Fx 29+ */

    @-webkit-keyframes scale {
        from {
            transform: scale($size);
        }
        to {
            transform: scale($size);
        }
    }
    @-moz-keyframes spin {
        from {
            transform: scale($size);
        }
        to {
            transform: scale($size);
        }
    }
    @-o-keyframes spin {
        from {
            transform: scale($size);
        }
        to {
            transform: scale($size);
        }
    }
    @keyframes spin {
        from {
            transform: scale($size);
        }
        to {
            transform: scale($size);
        }
    }
}

@mixin stretchDelay($duration) {
    -webkit-animation: stretchDelay $duration infinite ease-in-out; /* Safari 4+ */
    -moz-animation: stretchDelay $duration infinite ease-in-out; /* Fx 5+ */
    -o-animation: stretchDelay $duration infinite ease-in-out; /* Opera 12+ */
    animation: stretchDelay $duration infinite ease-in-out; /* IE 10+, Fx 29+ */

    @-webkit-keyframes stretchDelay {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4);
        }
        20% {
            -webkit-transform: scaleY(1);
        }
    }

    @keyframes stretchDelay {
        0%,
        40%,
        100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }
        20% {
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
        }
    }
}

@mixin transition($type, $duration, $effect) {
    -o-transition: $type $duration $effect;
    -ms-transition: $type $duration $effect;
    -moz-transition: $type $duration $effect;
    -webkit-transition: $type $duration $effect;
    transition: $type $duration $effect;
}

@mixin dropShadow($xLength, $yLength, $blur, $spread, $colour) {
    -webkit-box-shadow: $xLength $yLength $blur $spread $colour;
    -moz-box-shadow: $xLength $yLength $blur $spread $colour;
    box-shadow: $xLength $yLength $blur $spread $colour;
}
