.pagination {
    user-select: none;
    text-align: right;
}

.pagination__page-list {
    list-style: none;
}
.pagination__page-list > li {
    display: inline-block;
    position: relative;
}
.pagination__page {
    display: inline-block;
    font-size: 13px;
    margin: 0 2px;
    padding: 0 10px;
    user-select: none;
    text-align: center;
    border: 1px solid $shadow-grey;
    background-color: $border-grey;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    min-width: 38px;
    border-radius: 3px;
    line-height: 36px;

    &.disabled {
        cursor: not-allowed !important;
    }
}
.pagination__page:hover {
    background-color: $primary-lighter;

    &.disabled {
        background-color: $border-grey;
    }
}
.pagination__page:active {
    background-color: $primary-light;
}
.pagination__page.current {
    color: white;
    background-color: $off-black;
    border: 1px solid $off-black;

    &.disabled {
        background-color: $primary;
    }
}
.pagination__page.hidden {
    cursor: auto;
    background-color: $off-light;
    border-color: $border-grey;
    color: $border-grey;

    &.disabled {
        background-color: $off-light;
    }
}

.pagination__page-input-holder {
    width: 80px;
    padding: 0;
    position: absolute;
    max-height: 0;
    height: 0;
    overflow: hidden;
    left: -19px;
    z-index: 333;
    transform: scale(1, 0);
    transition: 0.2s ease-in-out;
    top: -40px;
}
.pagination__page-input-holder_visible {
    padding: 12px;
    top: -73px;
    max-height: 120px;
    height: 120px;
    transform: scale(1, 1);
}

.pagination__page-input-background {
    top: 4px;
    left: 4px;
    position: absolute;
    background: $border-grey;
    width: 70px;
    padding: 30px;
    text-align: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 14px;
}
.pagination__page-submit {
    visibility: hidden;
}

.pagination__page-input-background:after {
    content: '';
    position: absolute;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: -10px;
    left: 25px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent $border-grey $border-grey transparent;
}

.pagination__page-input-container {
    height: 100%;
    text-align: center;
}
.pagination__page-input-container div {
    margin-bottom: 3px;
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 4px;
}
.pagination__page-input {
    text-align: center;
    border-radius: 6px;
    position: relative;
    width: 52px;
    line-height: 22px;
    border: 1px solid $border-grey;
    background-color: white;
}
.pagination__page-input.error {
    border: 1px solid red;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.pagination__description {
    font-size: 13px;
    & option,
    & select {
        font-size: 13px;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
